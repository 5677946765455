/* WorkExperience.css */

.experience-timeline {
  position: relative;
  margin: 20px 0;
  padding-left: 40px; /* Space for the icon */
  border-left: 2px solid #4CAF50; /* Left border for timeline */
}

.experience-item {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
}

body.dark-mode .experience-item {
  background: #444; /* Darker background for dark mode */
  border-color: #555; /* Darker border for dark mode */
}

.experience-icon {
  position: absolute;
  left: -30px; /* Positioning for timeline */
  top: 10px; /* Align with item */
  font-size: 1.5em;
  color: #4CAF50;
}

.experience-content h4 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

body.dark-mode .experience-content h4 {
  color: #f0f2f5; /* Lighter text color for dark mode */
}

.experience-content p {
  margin: 5px 0;
  line-height: 1.5; /* Improve readability */
  color: #333;
}

body.dark-mode .experience-content p {
  color: #f0f2f5; /* Lighter text color for dark mode */
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.image-stack {
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center images */
  align-items: center; /* Vertical centering */
  margin: 40px auto; /* Centering stack */
  overflow: hidden; /* Hide overflow */
  width: 100%; /* Full width */
  max-width: 600px; /* Max width */
  border: 2px solid #ccc; /* Optional border for image stack */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

body.dark-mode .image-stack {
  border-color: #555; /* Darker border for dark mode */
}

.stack-image {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

body.dark-mode .stack-image {
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2); /* Lighter shadow for dark mode */
}

.stack-image:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

.image-caption {
  text-align: center; /* Center the caption */
  margin-top: 10px; /* Space above caption */
  font-size: 0.9rem; /* Caption font size */
  color: #555; /* Caption color */
}

body.dark-mode .image-caption {
  color: #ccc; /* Lighter caption color for dark mode */
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons */
  margin-top: 20px; /* Space above buttons */
}

/* Add button styles for navigation */
button {
  background-color: #4CAF50; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding */
  cursor: pointer; /* Pointer on hover */
  margin: 5px; /* Spacing between buttons */
  transition: background-color 0.3s; /* Smooth background change */
}

button:hover {
  background-color: #45a049; /* Darken button on hover */
}

body.dark-mode button {
  background-color: #388e3c; /* Darker button color for dark mode */
}

body.dark-mode button:hover {
  background-color: #2e7d32; /* Darken button on hover for dark mode */
}

@media (max-width: 600px) {
  .experience-content h4 {
    font-size: 1.1em; /* Adjust font size for smaller screens */
  }

  .stack-image {
    border-radius: 5px; /* Less rounding on small screens */
  }

  button {
    padding: 8px 12px; /* Adjust button padding for small screens */
  }
}