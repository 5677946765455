/* Import custom font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body.light-mode {
  background-color: #f0f2f5;
  color: #333;
}

body.dark-mode {
  background-color: #333;
  color: #f0f2f5;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App-header {
  background-color: #4caf50;
  padding: 20px;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 2.5em;
  margin: 0;
}

p {
  font-size: 1.2em;
}

main {
  padding: 20px;
}

.App-section {
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  text-align: left;
}

body.dark-mode .App-section {
  background-color: #444;
  border-color: #555;
}

.App-section h2 {
  color: #4caf50;
  font-size: 2em;
  margin-bottom: 20px;
}

.App-section ul {
  list-style-type: none;
  padding: 0;
}

.App-section li {
  background: #e8f5e9;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
}

body.dark-mode .App-section li {
  background: #555;
}

.App-section li:hover {
  background: #c8e6c9;
  transform: scale(1.05);
}

body.dark-mode .App-section li:hover {
  background: #666;
}

.App-section li h3 {
  margin: 0;
  color: #388e3c;
}

body.dark-mode .App-section li h3 {
  color: #a5d6a7;
}

.App-section li a {
  color: #4caf50;
  text-decoration: none;
  font-weight: bold;
}

body.dark-mode .App-section li a {
  color: #a5d6a7;
}

a {
  color: #4caf50;
  text-decoration: none;
}

body.dark-mode a {
  color: #a5d6a7;
}

a:hover {
  text-decoration: underline;
}

i {
  margin-right: 8px;
}

.App-footer {
  padding: 20px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 0.9em;
}

body.dark-mode .App-footer {
  background-color: #388e3c;
}
.project-card {
  position: relative;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}

body.dark-mode .project-card {
  background: #444;
  border-color: #555;
}

.project-card h3 {
  margin: 0;
  color: #4caf50;
}

body.dark-mode .project-card h3 {
  color: #a5d6a7;
}

.project-card p {
  color: #333;
}

body.dark-mode .project-card p {
  color: #f0f2f5;
}

.contact-info {
  margin: 20px 0;
  font-size: 1.1em;
}

.contact-card {
  background-color: #ffffff;
  border: 1px solid #4caf50;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

body.dark-mode .contact-card {
  background-color: #444;
  border-color: #555;
}

.contact-card h3 {
  margin-bottom: 15px;
  color: #4caf50;
}

body.dark-mode .contact-card h3 {
  color: #a5d6a7;
}

.contact-card p {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-card i {
  margin-right: 8px;
  color: #4caf50;
}

body.dark-mode .contact-card i {
  color: #a5d6a7;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
  width: 100%;
}

.contact-button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

body.dark-mode .contact-button {
  background-color: #388e3c;
}

.contact-button:hover {
  background-color: #388e3c;
  transform: scale(1.05);
}

body.dark-mode .contact-button:hover {
  background-color: #2e7d32;
}

.contact-button i {
  margin-right: 8px;
}

.profile-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 200px; /* Adjusted size */
  height: 200px; /* Adjusted size */
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
}

.dark-mode-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

body.dark-mode .dark-mode-toggle {
  color: #f0f2f5;
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 2em;
  }
  
  .App-section h2 {
    font-size: 1.5em;
  }
  
  .profile-image {
    width: 150px; /* Adjusted size for smaller screens */
    height: 150px; /* Adjusted size for smaller screens */
  }
  
  .contact-card {
    max-width: 90%;
    width: 100%;
  }
}

nav {
  margin-top: 20px;
  background-color: #4caf50;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

body.dark-mode nav {
  background-color: #2e7d32; /* Darker green for dark mode */
}

nav a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  transition: background-color 0.3s, transform 0.3s;
}

nav a:hover {
  text-decoration: none;
  background-color: #388e3c;
  transform: scale(1.05);
  border-radius: 5px;
}

body.dark-mode nav a:hover {
  background-color: #1b5e20; /* Darker shade for hover effect in dark mode */
}
.footer {
  background-color: #4caf50;
  color: white;
  padding: 10px 0;
  text-align: center;
  margin-top: 20px;
}

body.dark-mode .footer {
  background-color: #2e7d32;
}

.footer p {
  margin: 0;
  font-size: 0.9em;
}

.footer a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.footer a:hover {
  color: #c8e6c9;
}

.timeline {
  position: relative;
  padding: 20px 0;
  border-left: 2px solid #4caf50;
}

body.dark-mode .timeline {
  border-color: #388e3c;
}

.timeline-event {
  position: relative;
  padding: 10px 20px;
  margin: 0 0 20px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

body.dark-mode .timeline-event {
  background-color: #444;
}

.timeline-event h4 {
  color: #4caf50;
  margin: 0;
}

body.dark-mode .timeline-event h4 {
  color: #a5d6a7;
}

.timeline-event p {
  margin: 5px 0 0;
  color: #333;
}

body.dark-mode .timeline-event p {
  color: #f0f2f5;
}

.timeline-event::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 20px;
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border-radius: 50%;
}

body.dark-mode .timeline-event::before {
  background-color: #388e3c;
}

/* Custom button styling */
.custom-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

body.dark-mode .custom-button {
  background-color: #388e3c;
}

.custom-button:hover {
  background-color: #388e3c;
  transform: scale(1.05);
}

body.dark-mode .custom-button:hover {
  background-color: #2e7d32;
}

/* Scroll to top button */
.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 1000; /* Ensure it is above other elements */
}

body.dark-mode .scroll-top {
  background-color: #388e3c;
}

.scroll-top:hover {
  background-color: #388e3c;
  transform: scale(1.1);
}

body.dark-mode .scroll-top:hover {
  background-color: #2e7d32;
}
